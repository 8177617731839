.text {
    display: inline-block;
    color: $black;

    &--larger {
        font-size: 28px;
        line-height: 34px;
    }

    &--large {
        font-size: 22px;
        line-height: 29px;
    }

    &--modalTitle {
        font-size: 18px;
        line-height: 22px;
    }

    &--small {
        font-size: 14px;
        line-height: 17px;
    }

    &--smaller {
        font-size: 12px;
        line-height: 14px;
    }

    &--light {
        font-weight: 300;
    }

    &--medium {
        font-weight: 500;
    }

    &--bold {
        font-weight: 700;
    }

    &--gray {
        color: $cool-grey;
    }

    &--brownish-gray {
        color: $brownish-grey;
    }

    &--danger {
        color: $netlink-purple;
    }

    &--olddanger {
        color: $danger;
    }

    &__button {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        font-weight: 500;
        color: black;
        opacity: 1;

        &:hover {
            color: $brand-color !important;
        }

        &--individualParked{
            color: gray;
            padding: 0 2px 0 15px;
        }

        &--skroutzParked{
            background-color: $skroutz;
            border-radius: 5px;
            color: white;
            margin: 0 2px 0 15px;
            padding:2px;

            &:hover {
                background-color: orange;
                color: white !important;
            }
        }

        &--selected {
            color: $brand-color;
        }

        &--disabled {
            opacity: 0.2;
            pointer-events: none;
        }

        &--parked {
           padding: 0 2px 0 15px;
        }

        &--options {
           padding: 0 5px 0 15px;
        }

        &--delete {
            border-radius: 50%;
            width: 13px;
            height: 13px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__panel {
        position: absolute;
        background-color: #f9f9f9;
        bottom: 55px;
        left: 0;
        right: 0;
        padding: 20px 0;
        justify-content: center;
        align-items: center;
        display: flex;
        display: -webkit-flex;
        display: flex;
        border: 1px solid #ccc;
        flex-wrap: wrap;
    }
}
